<template>
  <Row class="page3">
    <Col style="width:48%;padding:0 1%;">
      <div class="description">
        <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span v-if="classifyExplain">{{classifyExplain}}</span>
          <span v-else style="text-align:center;font-size: 18px;">暂无简介......</span>
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width:51.5%;height: 91%;display: flex;flex-direction: column">
      <div class="top-list">
        <span class='title'><span class="title-text">项目背景介绍</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <span class="describe">在宁乡金洲村，佛光强县志质者为一名受灾“五保户”捡拾时，刚到门口等接到一段浓浓的刺皇异味，但老人家还住在里面。去愿者们奋战10多人，把房间清归得干净舒活。我们在同行的草位闻事的下小时讲课下，激动了地写:“现场，所有人，回想在日本听CODE日本志愿者看懂在救灾中的日常行动，当时我觉得日本救灾工作宣传，跟日本的大隔阂，现在我要说这话了，中国的灾祸的中国的志愿者部署甚微!</span>
      </div>
      <div class="center-list">
        <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <task-status ref="taskStatus"></task-status>
      </div>
      <div class="bottom-list" style="display:flex;">
        <div class="bottom-left">
          <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <workPics ref="workPic" :isBig="true"></workPics>
        </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class='title'><span class="title-text">{{ photoTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div v-for="(item,index) in classify" :key="index" @click="chooseNav(item,index)"
           :class="index==chooseIndex?'on_workname':'workname'">{{ item.classifyName }}
      </div>
    </div>
  </Row>
</template>

<script>
import {getDataApi, totalDataApi,getHonorData} from "@/api/dataList";
// import {handleOldNewArrFn} from "@/lib/oldNewArr";

const chinaMap = () => import('./components/chinaMap');
// const dataTotal =()=> import('./components/page4/data-total')
const taskStatus = () => import('./components/page4/taskStatus')
const workPics = () => import('./components/page2/workPictures');
const honorPics = () => import('./components/page2/HonoraryPics.vue');

export default {
  name: 'page4',
  components: {
    chinaMap,
    // dataTotal,
    taskStatus,
    workPics, //项目图片
    honorPics, //荣誉图片
  },
  data() {
    return {
      //tabNav子菜单
      classify: [],
      photoTitle:'湖湘公益汇',
      chooseIndex: 0,
      picsTitle: '', //右下角图片标题
      describeList: [],
      //地图展示项目数据
      resdata:[],
      //项目图片数组
      workPicsList: [],
      // 各项目累计数据
      totalSport: [],
      totalService: [],
      totalVolunteer: [],
      totalSchool: [],
      totalAttent: [],
      totalMoney: [],
      sumWork: 0,
      resizeFn:null,
      classifyExplain:'',
      vedioShow:false
    }
  },
  created() {
    // this.getData();
  },
  methods: {
    // 获取数据
    async getData() {
      const res = await totalDataApi(18);
      this.classify = res.data.classify;
      this.chooseNav(this.classify[0],0)
    },
    //  点击子菜单选择
    async chooseNav(item, index) {
      // console.log(item,'--item');
      const res = await getDataApi(item.classifyId);
      this.resdata = res.rows;
      this.chooseIndex = index;
      this.classifyExplain = item.classifyExplain //简介
      this.picsTitle = item.classifyName;   //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = item.img;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading = false;
      // 荣誉图片或者荣誉视频部分
      const HonRes = await getHonorData(item.classifyId)
      if(HonRes.code == 200) {
        console.log(HonRes);
        if(HonRes.data.switchOption==1){
          //荣誉图片
          this.$refs.honor.shows = 1
          this.photoTitle = '荣誉图片'
           this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
           this.$refs.honor.honoraryPicsList = item.honorImage;
        }else if(HonRes.data.switchOption==2){
          //精彩视频
          this.$refs.honor.shows = 2
          if(HonRes.data.videoUrl){
            HonRes.data.videoUrl = JSON.parse(HonRes.data.videoUrl)
          }
          this.photoTitle = HonRes.data.videoUrl[0].title||'精彩视频'
          console.log( this.photoTitle,'title');
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL = []
          this.$refs.honor.videoUrl = HonRes.data.videoUrl[0].url;
         
        }else if(HonRes.data.switchOption==3) {
          //精彩图片
          this.$refs.honor.shows = 3
          if(HonRes.data.photoUrl){
            HonRes.data.photoUrl = JSON.parse(HonRes.data.photoUrl)
          }
          this.photoTitle = '湖湘公益汇'
          this.$refs.honor.videoUrl = '';
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL =  HonRes.data.photoUrl
        }
      }else {
        this.$refs.honor.honoraryPicsList = [];
        this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
      }
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load = false; //加载中

      //每次点击导航选项将之前数据置空 再进行下面操作
      this.totalSport = []  //累计活动场次
      this.totalService = [] //累计服务人次
      this.totalAttent = []  //累计社会组织参与
      this.totalMoney = []   //累计投入款物
      this.totalVolunteer = []  //累计志愿者人数
      this.totalSchool = []  //累计覆盖学校数量

      for (var i = 0; i < this.resdata.length; i++) {
        // this.resdata[i].choiceIndex = index; //选择导航栏 把index对应传给choiceIndex 从而获取不同数据
        this.$refs.taskStatus.sportData[i].value = this.resdata[i].list[0].soprtcount //排行轮播活动场次

        // 累计各项数据 数组新接收
        this.totalSport.push(this.resdata[i].list[0].soprtcount);  //累计活动场次
        this.totalService.push(this.resdata[i].list[0].serviceCount);  //累计服务人次
        this.totalAttent.push(this.resdata[i].list[0].attent);  //累计社会组织参与
        this.totalMoney.push(this.resdata[i].list[0].money);  //累计投入款物
        this.totalVolunteer.push(this.resdata[i].list[0].volunteerCount);  //累计志愿者人数
        this.totalSchool.push(this.resdata[i].list[0].schoolCount);  //累计覆盖学校数量

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[0].attent) + Number(this.resdata[i].list[0].money)
            + Number(this.resdata[i].list[0].schoolCount) + Number(this.resdata[i].list[0].serviceCount)
            + Number(this.resdata[i].list[0].soprtcount) + Number(this.resdata[i].list[0].volunteerCount)
        this.resdata[i].value = totalValue;
      }
      // (父传子）累计各项数据
      this.$refs.taskStatus.sumWork[0].sumWorkList = this.sum(this.totalSport);  //累计活动场次
      this.$refs.taskStatus.sumWork[1].sumWorkList = this.sum(this.totalService);  //累计服务人次
      this.$refs.taskStatus.sumWork[2].sumWorkList = this.sum(this.totalAttent);  //累计社会组织参与
      this.$refs.taskStatus.sumWork[3].sumWorkList = this.sum(this.totalMoney);  //累计投入款物
      this.$refs.taskStatus.sumWork[4].sumWorkList = this.sum(this.totalVolunteer);  //累计志愿者人数
      this.$refs.taskStatus.sumWork[5].sumWorkList = this.sum(this.totalSchool);  //累计覆盖学校数量

      this.$refs.taskStatus.setChart();  //刷新轮播排行活动场次数据
      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
    },
    // 求项目累计数据函数
    sum(arr) {
      var s = 0;
      for (var i = 0; i < arr.length; i++) {
        s += Number(arr[i]);
      }
      return s;
    }
  },
  mounted() {
    this.getData();
    this.resizeFn = this.$debounce(()=> {
      this.$refs.chinaMap.setMap();
      this.$refs.taskStatus.setChart();
    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page3 {
  height: 99%;
  width: 100%;
  padding: 8px 20px 20px;
  background: #03044A;
  overflow: hidden;
  position: relative;

  .tabNav {
    cursor: pointer;
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.3%;
    left: 35%;

    .workname {
      color: #fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right: 1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }

    .on_workname {
      color: #4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }

  .ivu-col {
    height: 100%;
    float: left;
  }

  .right1-1, .center-bottom, .description {
    height: 95%;
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;

    #left_5 {
      height: 100%;
      width: 45%;
      float: left;
    }

    #left_6 {
      height: 100%;
      width: 55%;
      float: left;
    }

    .circular {
      height: 100%;

      .canvas {
        height: 100%;
        width: 30%;
        float: left;

        .subtitle {
          font-size: 12px;
          font-weight: bold;
          color: #fff;
          height: 25px;
          padding: 10px 0;
          text-align: center;
        }

        .canvasList {
          height: calc(~'100% - 25px');
          text-align: center
        }
      }
    }

    .left1 {
      border-bottom: 1px solid #0D2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
      }
    }
  }

  .left {
    height: 76%;
  }

  .description {
    background: none !important;
    height: 20% !important;
    box-sizing: border-box;
    padding-bottom: 2%;
  }

  .describeList {
    text-indent: 3%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 2% auto;
    padding-top: 1%;
    padding-bottom: 1%;
    align-items: center;
    color: rgb(167, 198, 235);
    line-height: 27px;
    font-size: 15px;
    overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;

    span {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .describeList::-webkit-scrollbar { /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .describeList::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #a1b3dc;
  }

  .describeList::-webkit-scrollbar-track { /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: rgb(8, 25, 79);
  }

  .top-list, .center-list, .bottom-left, .bottom-right {
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;
    box-sizing: border-box;

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }

  .bottom-left, .bottom-right {
    width: 50%;
  }

  .top-list {
    height: 18%;
    padding: 0 10px;

    .describe {
      display: inline-block;
      margin-top: 20px;
      //width: 30%;
      height: 98%;
      font-size: 17px;
      color: #3d83e7;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
  }

  .center-list {
    height: 30%;
    margin: 20px 0;
  }

  .bottom-list {
    width: 100%;
    height: 52%;
  }

  .center-bottom {
    height: 40%;

    .bottom-radars {
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
  }

  .right-1 {
    height: 30%;

    .right1-1 {
      height: 92%;
    }
  }

  .right-2 {
    height: 40%;

  }
}
</style>
